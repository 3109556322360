/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-almost-invisible-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-almost-invisible-hearing-aid",
    "aria-label": "the almost invisible hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Almost Invisible Hearing Aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia Insio is a ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "‘completely-in-canal’ (CIC) hearing aid"), " that fits comfortably and discreetly in the ear, making it virtually invisible to the naked eye. Available for patients with up to severe and profound hearing loss, the Insio devices feature higher amplification, discreet design, and wireless functionality to deliver the best performance in the smallest package."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "in-brief-signia-insio",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-brief-signia-insio",
    "aria-label": "in brief signia insio permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In Brief: Signia Insio"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Advanced BestSound™ Technology"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Insio models with two directional microphones—ITEs and ITCs—are so advanced that wearers can understand speech in demanding environments even better than those with normal hearing"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Up to 65 dB gain, even in the tiny IIC"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "CICs and ITCs, even the power models, can be built smaller with improved performance"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Binaural OneMic directionality in CICs and IICs provide superior speech understanding thanks to enhanced directionality"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Premium wireless connectivity for absolutely convenient control and audio streaming from Bluetooth-enabled devices"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A tinnitus function to manage tinnitus and improve hearing at the same time Vent for optimal ear ventilation and minimized occlusion"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "binaural-directionality-technology--wireless-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#binaural-directionality-technology--wireless-connectivity",
    "aria-label": "binaural directionality technology  wireless connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Binaural Directionality Technology & Wireless Connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One big feature in the Insio hearing aids is binaural directionality technology, which allows the hearing aids in both ears to communicate with each other. This is important because two hearing aids working as a single unit creates the most natural and superior hearing experience in any sound environment. When your environment changes, the hearing aids talk with each other and automatically adjust themselves without any effort on your part. It’s as if they are not even there."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All the Insio models can be controlled remotely with the touchControl App™. This removes the need for having an extra remote control device to adjust volumes or settings, since it can be done with your smartphone instead. With the possibility of wireless communication, the Insio can also connect to your TV and computer to stream content directly to the hearing aids."), "\n", React.createElement(ButtonCta, {
    copy: "TRY THE SIGNIA INSIO NOW",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-nx-logo.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-nx-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-nx-technology",
    "aria-label": "signia nx technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/x",
    className: "c-md-a"
  }, "Signia Nx Technology")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Xperience is a revolutionary technology platform from Signia, the company that has taken over Siemens’ well-known range of audiological devices. Available in Behind-the-Ear and Receiver-in-Canal models, Xperience is one of the most advanced hearing aid technologies around.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-silk.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-silk-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk-x",
    "aria-label": "signia silk x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/silk",
    className: "c-md-a"
  }, "Signia Silk X")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for a hearing aid that is as small as a coffee bean and unnoticeable to those around you, the Signia Silk X is the perfect fit. It’s one of the smallest hearing aids on the market today, disappearing inside your ear. These ITE hearing aids also include “Click Sleeves,” which make them simple and comfortable to wear, as they’re made out of soft silicone that molds itself to the shape of your ear canal.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-dark-granite.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-styletto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto",
    "aria-label": "signia styletto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/styletto",
    className: "c-md-a"
  }, "Signia Styletto")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "This slim, behind-the-ear Bluetooth hearing aid has transformed the future of modern hearing aid design. Designed for the active lifestyle, the rechargeable Styletto X hearing aid combines elegance, convenience and functionality to deliver a high-quality hearing experience without compromising style. And no one will know you’re wearing it.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/binaural-hearing-aids.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure",
    "aria-label": "signia pure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/pure",
    className: "c-md-a"
  }, "Signia Pure")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia range of Pure hearing aids is incredible no matter your level of hearing loss. With these powerful Bluetooth hearing aids that sit discreetly behind your ear, you’ll be able to stream phone calls, TV and audio right into your ears.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
